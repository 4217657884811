/* eslint-disable no-warning-comments */
import gsap from 'gsap'
import React, {useEffect, useRef} from 'react'

import {TRANSITION_ENTER} from '@/components/PageTransition'

export const SplashScreen = () => {
  const initialPannel = useRef(null)
  const initialInnerPannel = useRef(null)

  useEffect(() => {
    // const context = gsap.context(async () => {
    //   await document.fonts.ready
    //   ScrollTrigger.refresh()
    //   const tl = gsap.timeline()
    //   tl.set(initialInnerPannel.current, {y: window.innerHeight})
    //   tl.set(initialInnerPannel.current, {opacity: 1})
    //   tl.to(initialInnerPannel.current, {y: 0, duration: TRANSITION_ENTER - 0.1})
    //   tl.to(initialPannel.current, {opacity: 0, duration: 0.1})
    //   tl.set(initialPannel.current, {height: 0})
    //   tl.set(initialPannel.current, {display: 'none'})
    // })

    const context = gsap.context(async () => {
      const tl = gsap.timeline()
      tl.set(initialInnerPannel.current, {y: window.innerHeight})
      tl.set(initialInnerPannel.current, {opacity: 1})
      tl.to(initialInnerPannel.current, {y: 0, duration: TRANSITION_ENTER})
      tl.to(initialPannel.current, {opacity: 0, duration: 0.1})
      tl.set(initialPannel.current, {height: 0})
      tl.set(initialPannel.current, {display: 'none'})
    })

    return () => context.revert()
  }, [])

  return (
    <div
      className='pointer-events-none fixed left-0 top-0 z-50 flex h-screen w-screen items-center justify-center bg-beige-100'
      ref={initialPannel}
    >
      <div
        className='pointer-events-none fixed left-4 top-4 z-50 h-screen w-[calc(100%-32px)] rounded-t-xl bg-white opacity-0'
        ref={initialInnerPannel}
      />
    </div>
  )
}
